<template>
  <div class="hello">
    <input type="file" id="file0" ref="document0"  @change="getFile($event,0)"  />

     <!-- <van-uploader @change="getFile($event,0)" id="file0" ref="document0" v-model="fileList"  multiple :max-count="1"  /> -->

    <!-- <p v-if="message" class="messagetip">{{message}}</p> -->

    <div class="cover" v-if="show"></div>

    <div class="bordercrop" v-if="show">
      <p><span @click="nostart">取消</span><span @click="startCrop" :class="{hs:acti?true:false}">确定上传</span></p>
      <VueCropper id="cropper"
          ref="cropper" :img="url"
          :outputSize="option.outputSize"
          :outputType="option.outputType"
          :canMove='option.canMove'
          :autoCrop="option.autoCrop"
          :fixedBox="option.fixedBox"
          :original='option.original'
          :infoTrue="option.infoTrue"
          :centerBox='option.centerBox'
          :canMoveBox='option.canMoveBox'
          :canScale='option.canScale'
          :fixed='option.fixed'
          :fixedNumber='option.fixedNumber' ></VueCropper>
      
    </div>


  </div>
</template>

<script>
  //这个页面有几个传图片的地方就用几个id
  import {VueCropper}  from 'vue-cropper'
  export default {
    name: 'uploadimg',
    components:{VueCropper},
    props:['wid','hei'], //宽高大小设定
    data () {
      return {
        fileList:[],
        show:false,
        file:'',
        message:'',
        c_id:'',
        z_id:'',
        // event:'',
        option: {
          outputSize: 1, // 裁剪生成图片的质量 0.1 - 1
          outputType: 'jpg', //	裁剪生成图片的格式 jpeg || png || webp
          canScale: true, // 图片是否允许滚轮缩放 默认true
          autoCrop: true, // 是否默认生成截图框 默认false
          canMove: true, //上传图片是否可以移动 默认true
          // autoCropWidth: 375, //默认生成截图框宽度	容器的80%	0~max
          // autoCropHeight: 375, //默认生成截图框高度	容器的80%	0~max
          fixedBox: false, // 固定截图框大小 不允许改变	false	true | false 不能改变
          fixed: true, //是否开启截图框宽高固定比例
          original: false, // 上传图片按照原始比例渲染	false	true | false
          infoTrue: false, // 为展示真实输出图片宽高 false 展示看到的截图框宽高	false	true | false
          centerBox: true, // 截图框是否被限制在图片里面	false	true | false
          canMoveBox: false, //截图框能否拖动	true	true | false
          fixedNumber: [1,1], // 截图框的宽高比例 [宽度, 高度]
        },
        url: '',
        // percentage: 0 ,// 上传进度
        // imgUrl: [],
        after: '',
        pad:false,
        acti:false,//点击计算显示灰色
        is_self:'',
      }
    },
    mounted(){
      // //console.log(1)
      // //console.log(this.wid,this.hei);
      this.option.fixedNumber = [this.wid,this.hei]


    },
    watch:{
      wid(a,b){
        console.log(a,b)
        this.wid = a;
        this.option.fixedNumber = [a,this.hei]
      },
      hei(a,b){
        console.log(a,b)
        this.hei = a;
        this.option.fixedNumber = [this.wid,a]
        // //console.log(this.option.fixedNumber)
      }
    },
    methods:{
      //不上传
      nostart(){
        this.show = false;
      },

      //裁剪图片
      startCrop() {
      // 获取cropper的截图的base64 数据
        this.$refs.cropper.getCropData(data => {

            // 获取文件对象
            let pic = this.dataURLtoFile(data,this.file.name)
            this.upload(pic)
        })
       },
       //将base64转换为文件的函数
        dataURLtoFile(dataurl, filename) {

          var blob = this.base64ToBlob(dataurl,filename);

          var file = this.blobToFile(blob, filename);

        // //console.log(blob,file)

          return file

       },

       //将blob转换为file

       //第二步：二进制转file

        blobToFile(theBlob, fileName) {

            theBlob.lastModifiedDate = new Date();

            theBlob.name = fileName;

            return theBlob;

       },
       base64ToBlob(urlData){
         // //console.log(urlData)
         var bytes=window.atob(urlData.split(',')[1]);        //去掉url的头，并转换为byte
          var tt = urlData.split(';')[0];
          var ff = tt.split(':')[1];
          // //console.log(ff)

           //处理异常,将ascii码小于0的转换为大于0
           var ab = new ArrayBuffer(bytes.length);
           var ia = new Uint8Array(ab);
           for (var i = 0; i < bytes.length; i++) {
               ia[i] = bytes.charCodeAt(i);
           }
           return new Blob( [ab] , {type : ff});
       },



      //判断照片是否合规矩
      getFile(event,a){
        this.url = '';
        console.log(event,a)
        var _this = this;
        this.file = event.target.files[0];
        //清空传过的图片数据
        this.$refs.document0.value = null;
        this.$forceUpdate();
          if(!this.file){
            return;
          }

            // 创建文件读取对象
            var reader = new FileReader()
            reader.readAsDataURL(this.file)
            // 文件读取结束
            reader.onload = function(e) {
              // //console.log(789)
              console.log(e)
              // 此处this === reader
              _this.url = '';
              _this.url = this.result
            }

            _this.show = true;

      },
      //图片正式上传函数
      upload(datas){
        if(!this.acti){
          this.$toast.loading({ 
            message: '上传中',
            forbidClick: true,
          });

          this.acti = true;
          var data = new FormData();//重点在这里 如果使用 var data = {}; data.inputfile=... 这样的方式不能正常上传
          this.z_id = this.$route.query.z_id || this.Storage.get("z_id")
          this.c_id = this.Storage.get("userBaseInfo").id
          data.append("image",datas);
          data.append("c_id",this.c_id);
          data.append("z_id",this.z_id);
          data.append("userId",this.Storage.get("baseInfo").userId);
          var _this = this
          this.axios({
            url:'/scrm/moment/zone/cover',
            method:'POST',
            data:data
          }).then(function(res){
            if(res.data.code == 0){
              _this.$emit('toimg',res.data.data.cover)
              _this.show = false;
              _this.acti = false;
              _this.file = '';
              _this.$toast.clear();
              _this.$toast.success('上传成功');
            }else{
              _this.$toast(res.data.message);
            }
          })
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .cover{position: fixed;width: 100%;height: 100%;background: black;top: 0;left: 0;opacity: 0.6;z-index: 20;}
  .picxz{width: 20rem;}
  .bordercrop{width: 100%;height:100%;position: fixed;top: 0;bottom: 0;left: 0;right: 0;margin: auto auto;background: white;paint-order: 0.2rem;z-index: 20;}
  #cropper{width: 100%;height: 100%;margin: 0 auto;}
  .bordercrop .hs{background: #ccc;}
  .bordercrop p{text-align: right;    position: fixed;
    top: 0;
    z-index: 10;padding: 0.1rem;box-sizing: border-box;
    width: 100%;margin: 0 auto;display: flex;justify-content: space-between;}
   .bordercrop span{
    height: 0.28rem;
    padding: 0 0.1rem;
    line-height: 0.3rem;
    text-align: center;
    border-radius: 5px;
    font-size: 0.14rem;
    background: #08c163;
    color: white;
    margin-bottom: 5px;
    }


  input[type='file']{display: none;}

  .messagetip{z-index: 9;width: 3rem;height: 0.5rem;padding: 0.12rem 0.22rem;background: black;opacity: 0.7;margin: auto auto;position: fixed;top: 0;bottom: 0;left: 0;right: 0;color: white;text-align: center;line-height: 0.5rem;border-radius: 0.1rem;}
  .fenye{margin-top: 0.4rem;display: flex;text-align: center;justify-content: center;}
  .fenye span{cursor: pointer;padding: 0.08rem 0.14rem;border:0.01rem solid rgba(240,240,240,1);margin-right: 0.13rem;color: #999999;border-radius:0.03rem;}
  .fenye span:hover{background:rgba(255,212,1,1);color:rgba(51,51,51,1);}
  .fenye .xuan{background:rgba(255,212,1,1);color:rgba(51,51,51,1)}
  .yst span{background:white}


  .yl span{border:0.01rem solid rgba(75,103,231,1);border-radius:0;color: rgba(75,103,231,1);padding: 0.08rem 0.11rem}
  .yl span:hover{background:rgba(75,103,231,1);color: white;}
  .yl .xuan{background:rgba(75,103,231,1);color: white;}
</style>
