<template>
  <div id="CircleOfFriends">
    <uploadimg wid = "400" hei = "400" @toimg="getimg" ></uploadimg>

    <div class="headerimg">
      <div class="backimg">
        <img @click="changeBg" :src="bg" />
        <div></div>
      </div>
      <van-icon name="replay" class="refresh" @click="refreshLink" />
      <img class="tocircle" @click.stop="showPopup" v-if="userBaseInfo.is_self == 1" src="~@/assets/image/photo.png" />
      <img class="bottomright" :src="master.avatar" />
      <p>{{master.name}}</p>
    </div>

    <van-empty v-if="tableData.length == 0" description="没有消息~" />

    <van-list v-else
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
       
      
      <div class="content">
        <div class="messageTitle" v-if="unreadCount && userBaseInfo.is_self == 1">
          <div @click="toMessageList">
            <!-- <img src="http://moment.wptomo.top/bg.jpg" && userBaseInfo.is_self == 1/> -->
             {{unreadCount}}条新消息 
          </div>
        </div>

        <div class="selfitem" v-for="(i,index) in tableData" :key="index">
          <img :src="master.avatar" />
          <div>
            <p>{{master.name}}</p>
            <p class="changehtml" v-html="i.content"></p>
            <!-- 图片处理 -->
            <div v-if="i.media_2" :class="{'img2': (i.media_2 && !i.media_3)|| (i.media_4 && !i.media_5),'img3':!((i.media_2 && !i.media_3) || (i.media_4 && !i.media_5)) || i.media2}">
              <div v-if="i.media_1"><img  @click="showImg(0,index)"  :src="i.media_1" /></div>
              <div v-if="i.media_2"><img  @click="showImg(1,index)"  :src="i.media_2" /></div>
              <div v-if="i.media_3"><img  @click="showImg(2,index)"  :src="i.media_3" /></div>
              <div v-if="i.media_4"><img  @click="showImg(3,index)"  :src="i.media_4" /></div>
              <div v-if="i.media_5"><img  @click="showImg(4,index)"  :src="i.media_5" /></div>
              <div v-if="i.media_6"><img  @click="showImg(5,index)"  :src="i.media_6" /></div>
              <div v-if="i.media_7"><img  @click="showImg(6,index)"  :src="i.media_7" /></div>
              <div v-if="i.media_8"><img  @click="showImg(7,index)"  :src="i.media_8" /></div>
              <div v-if="i.media_9"><img  @click="showImg(8,index)"  :src="i.media_9" /></div>
              <div v-if="i.media_5 && !i.media_6"></div>
              <div v-if="i.media_8 && !i.media_9"></div>
            </div>
            <div v-else-if="i.media_1" class="img1">
              <img  @click="showImg(0,index)"  :src="i.media_1" />
            </div>
            <div v-else></div>
            <div>
              <span>{{Global.formatTimes(i.created_at)}}<span @click="delCircle(index,i.id)" v-if="userBaseInfo.is_self == 1">删除</span></span>
              <span @click.stop="showTwoClick($event,index)"><span></span></span>
            </div>
            <div class="comments"  v-if="i.moment_comment.length || i.vote_list.length">
              <div class="clickheart" v-if="i.vote_list.length">
                <img class="tocircle" src="~@/assets/image/heart1.png" />
                <span>{{i.voteCommon}}</span>
              </div>
              <div class="line" v-if="i.vote_list.length && i.moment_comment.length"></div>
              <div class="detailcomments">
                <div v-for="(j,indexs) in i.moment_comment" :key="indexs" @click.stop="toComment(1,j.tag,index,j.from,indexs,j.id)">
                  <span>{{j.from_name}}：</span><span style="color:#141414;font-weight:normal" v-if="j.to_name">回复</span>
                  <span v-if="j.to_name">{{j.to_name}}：</span><span style="color:#141414;font-weight:normal" v-html="j.content"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="selfitem">
          <img src="http://moment.wptomo.top/bg.jpg" />
          <div>
            <p>N17甜品蛋糕屋无图</p>
            <p>甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你</p>
            <div></div>
            <div>
              <span>时间31分钟前<span>本人可删除</span></span>
              <span @click.stop="showTwoClick($event,0)"><span></span></span>
            </div>
            <div class="comments">
              <div class="clickheart">
                <img class="tocircle" src="~@/assets/image/heart1.png" />
                <span>{{comep1}}</span>
              </div>
              <div class="line"></div>
              <div class="detailcomments">
                <div><span>弄饭收了快递费：</span>是打发尼可拉斯那个猎人什么的</div>
                <div><span>的肺宁颗粒双方各</span>回复<span>是打发感觉可累讽德诵功：</span>我平日靠欺骗,温柔犯了啥猛地发力省得卖飞了看电视没发过的个</div>
              </div>
            </div>
          </div>
        </div>
        <div class="selfitem">
          <img src="http://moment.wptomo.top/bg.jpg" />
          <div>
            <p>N17甜品蛋糕屋1张图</p>
            <p>甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你</p>
            <div class="img1">
              <img @click="showImg('http://moment.wptomo.top/bg.jpg')"  src="http://moment.wptomo.top/bg.jpg" />
            </div>
            <div>
              <span>时间31分钟前</span>
              <span @click.stop="showTwoClick($event,1)"><span></span></span>
            </div>
            <div class="comments">
              <div class="detailcomments">
                <div><span>弄饭收了快递费：</span>是打发尼可拉斯那个猎人什么的</div>
                <div><span>的肺宁颗粒双方各</span>回复<span>是打发感觉可累讽德诵功：</span>我平日靠欺骗,温柔犯了啥猛地发力省得卖飞了看电视没发过的个</div>
              </div>
            </div>
          </div>
        </div>
        <div class="selfitem">
          <img src="http://moment.wptomo.top/bg.jpg" />
          <div>
            <p>N17甜品蛋糕屋2张图</p>
            <p>甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你</p>
            <div class="img2">
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/lang.jpg" /></div>
            </div>
            <div>
              <span>时间31分钟前</span>
              <span @click.stop="showTwoClick($event,2)"><span></span></span>
            </div>
            <div class="comments">
              <div class="clickheart"><img class="tocircle" src="~@/assets/image/heart1.png" /> 比较一下,正所谓无,里是否有,谁知道,爱谁谁,拉倒,事发后了第三方的吗,啥地方官,大富我怒斥,撒菲菲,是打发履行,撒发送到法律</div>
            </div>
          </div>
        </div>
        <div class="selfitem">
          <img src="http://moment.wptomo.top/bg.jpg" />
          <div>
            <p>N17甜品蛋糕屋3张图</p>
            <p>甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你</p>
            <div class="img3">
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/lang.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
            </div>
            <div>
              <span>时间31分钟前</span>
              <span @click.stop="showTwoClick($event,3)"><span></span></span>
            </div>
          </div>
        </div>
        <div class="selfitem">
          <img src="http://moment.wptomo.top/bg.jpg" />
          <div>
            <p>N17甜品蛋糕屋4张图</p>
            <p>甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你</p>
            <div class="img4">
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/lang.jpg" /></div>
            </div>
            <div>
              <span>时间31分钟前</span>
              <span @click.stop="showTwoClick($event,4)"><span></span></span>
            </div>
          </div>
        </div>
        <div class="selfitem">
          <img src="http://moment.wptomo.top/bg.jpg" />
          <div>
            <p>N17甜品蛋糕屋5张图</p>
            <p>甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你</p>
            <div class="img5">
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div></div>
            </div>
            <div>
              <span>时间31分钟前</span>
              <span @click.stop="showTwoClick($event,5)"><span></span></span>
            </div>
          </div>
        </div>
        
        <div class="selfitem">
          <img src="http://moment.wptomo.top/bg.jpg" />
          <div>
            <p>N17甜品蛋糕屋6张图</p>
            <p>甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你</p>
            <div class="img6">
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
            </div>
            <div>
              <span>时间31分钟前</span>
              <span @click.stop="showTwoClick($event,6)"><span></span></span>
            </div>
          </div>
        </div>
        <div class="selfitem">
          <img src="http://moment.wptomo.top/bg.jpg" />
          <div>
            <p>N17甜品蛋糕屋7张图</p>
            <p>甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你</p>
            <div class="img7">
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
            </div>
            <div>
              <span>时间31分钟前</span>
              <span @click.stop="showTwoClick($event,7)"><span></span></span>
            </div>
          </div>
        </div>
        <div class="selfitem">
          <img src="http://moment.wptomo.top/bg.jpg" />
          <div>
            <p>N17甜品蛋糕屋8张图</p>
            <p>甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你</p>
            <div class="img8">
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div></div>
            </div>
            <div>
              <span>时间31分钟前</span>
              <span @click.stop="showTwoClick($event,8)"><span></span></span>
            </div>
          </div>
        </div>
        <div class="selfitem">
          <img src="http://moment.wptomo.top/bg.jpg" />
          <div>
            <p>N17甜品蛋糕屋9张图</p>
            <p>甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你甜甜的蛋糕和甜甜的你</p>
            <div class="img9">
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
              <div><img src="http://moment.wptomo.top/bg.jpg" /></div>
            </div>
            <div>
              <span>时间31分钟前</span>
              <span @click.stop="showTwoClick($event,9)"><span></span></span>
            </div>
          </div>
        </div> -->
        <!-- 点赞评论 -->
        <div class="hearcom" v-show="display" ref="hearcom">
          <div @click.stop="toLike"><img src="~@/assets/image/heart.png" />赞</div>
          <div @click.stop="toComment(0)"><img src="~@/assets/image/commentary.png" />评论</div>
        </div>
      </div>

      <!-- 发送评论 -->
      <div class="tocomment" id="allEmotion" v-if="comShow"  @click.stop>
        <div>
          <div class="trytoimg">
            <div  @input="change($event)" class="pre" ref="pres" contenteditable="true" tabindex="0" hidefocus="true" @click.stop="focus()"  v-html="$store.state.comContent" ></div>
            <!-- <van-field  v-model="$store.state.showcontent" @focus.stop="focus()" rows="1"  :autosize= {maxHeight:60}  type="textarea" placeholder="评论" /> -->
          </div>
          <!-- <textarea v-model="comContent" @focus.stop="focus()" rows="1" autofocus  type="textarea" placeholder="评论" ></textarea> -->
          <img id="face"  src="~@/assets/image/smile.png" />
          <span @click.stop="send()" :class="{'active':sendActive || $store.state.comContent}">发送</span>
        </div>
      </div>
      <!-- <van-uploader :after-read="afterRead" multiple :max-count="9" id="tocircle" /> -->
      <!-- 底部弹框 friedShow <span>照片或视频</span>-->
      <van-popup v-model="delectCom"  position="bottom" :style="{'border-top-left-radius': '10px','border-top-right-radius': '10px'}">
        <div class="bottompopup">
          <!-- <div><span>复制</span></div> -->
          <div @click="delComment">删除</div>
          <!-- <div></div> -->
          <div @click.stop="delectCom = false">取消</div>
        </div>
      </van-popup>

      <van-popup v-model="friedShow"  position="bottom" :style="{'border-top-left-radius': '10px','border-top-right-radius': '10px'}">
        <div class="bottompopup">
          <div @click="changeFile(0)"><span>照片</span></div>
          <div @click="changeFile(1)">视频</div>
          <div></div>
          <div @click.stop="friedShow = false">取消</div>
        </div>
      </van-popup>

    </van-list>
  </div>
</template>

<script>
import uploadimg from './uploadimg.vue'
import { ImagePreview } from 'vant';
import $ from 'jquery'
import  '../assets/js/jquery.sinaemotion'
export default {
  name: 'CircleOfFriends',
  components:{uploadimg},
  data(){
    return {
      display:false,//点赞评论悬浮黑框
      e:0,//点击的第几个
      comShow:false,//评论弹框
      conTimer:0,//滑动 评论框失效
      friedShow:false,//图片选择的方式弹框
      delectCom:false,//删除评论
      sendActive:'',
      userBaseInfo:'',//用户信息
      baseInfo:'',//id信息
      tableData:[],//朋友圈数据
      next_page_url:'',
      loading: false,
      finished: false,
      master:'',//页面主人信息
      unreadCount:'',//未读消息提示
      bg:'',//背景图
      commType:'',//评论还是回复
      index:'',//第几条评论
      commId:'',//评论id
    }
  },
  mounted(){
    this.baseInfo = this.Storage.get('baseInfo')
    this.axios({
      method: 'POST',
      url: '/scrm/auth/wework',
      data:{
        z_id:this.$route.query.z_id,
        userId:this.baseInfo.userId,
        external_userid:this.baseInfo.external_userid
      }
    }).then((res) => {
      if(res.data.code == 0){
        this.Storage.set('userBaseInfo', res.data.data)
        this.userBaseInfo = res.data.data;


        this.axios({
          method: 'GET',
          url: '/scrm/moment?z_id='+_this.$route.query.z_id+'&c_id='+_this.userBaseInfo.id+'&is_self='+_this.userBaseInfo.is_self
        }).then((res) => {
          if(res.data.code == 0){
            // console.log(res)
            _this.tableData = res.data.data.moments.data
            for(var i = 0;i<_this.tableData.length;i++){
              if(_this.tableData[i].vote_list.length){
                for(var j = 0;j<_this.tableData[i].vote_list.length;j++){
                  if(j==0){
                    _this.tableData[i].voteCommon = _this.tableData[i].vote_list[0].v_name
                  }else{
                    _this.tableData[i].voteCommon = _this.tableData[i].voteCommon +','+_this.tableData[i].vote_list[j].v_name
                  }
                }
              }
              if(_this.userBaseInfo.is_self !=1 ){
                if(_this.tableData[i].moment_comment.length){
                  for(var k = 0;k<_this.tableData[i].moment_comment.length;k++){
                    if(_this.tableData[i].moment_comment[k].tag != 1){ //如果不是空间主人的评论  进行判断
                      if(_this.tableData[i].moment_comment[k].from != _this.userBaseInfo.id){ //如果不是访客本身的则去掉
                        _this.tableData[i].moment_comment.splice(k,1)
                        k--;
                      }
                    }
                  }
                }
              }
            }
            _this.next_page_url = res.data.data.moments.next_page_url
            _this.master = res.data.data.zone.contact
            _this.bg = res.data.data.zone.cover || res.data.data.zone.contact.avatar
            _this.unreadCount = res.data.data.unreadCount
          }
        })
        
      }
    })

//     this.userBaseInfo = this.Storage.get('userBaseInfo')
    this.$store.state.comContent = ''
    var _this = this;
    document.addEventListener('click', _this.changeType)


  },
  methods:{
    refreshLink(){
      location.reload();
    },
    onLoad(){
      var _this = this;
      if(this.next_page_url){
        this.axios({
          method: 'GET',
          url: this.next_page_url+'&z_id='+_this.$route.query.z_id+'&c_id='+_this.userBaseInfo.id+'&is_self='+_this.userBaseInfo.is_self
        }).then((res) => {
          this.loading = false;
          if(res.data.code == 0){
            _this.tableData.push(...res.data.data.moments.data)

            for(var i = 0;i<_this.tableData.length;i++){
              if(_this.tableData[i].vote_list.length){
                for(var j = 0;j<_this.tableData[i].vote_list.length;j++){
                  if(j==0){
                    _this.tableData[i].voteCommon = _this.userBaseInfo.is_self == 1 ?( _this.tableData[i].vote_list[0].v_remark || _this.tableData[i].vote_list[0].v_name):_this.tableData[i].vote_list[0].v_name
                  }else{
                    _this.tableData[i].voteCommon = _this.tableData[i].voteCommon +','+_this.userBaseInfo.is_self == 1 ? (_this.tableData[i].vote_list[0].v_remark || _this.tableData[i].vote_list[0].v_name):_this.tableData[i].vote_list[0].v_name
                  }
                }
              }
               if(_this.userBaseInfo.is_self !=1 ){
                if(_this.tableData[i].moment_comment.length){
                  for(var k = 0;k<_this.tableData[i].moment_comment.length;k++){
                    if(_this.tableData[i].moment_comment[k].tag != 1){ //如果不是空间主人的评论  进行判断
                      if(_this.tableData[i].moment_comment[k].from != _this.userBaseInfo.id){ //如果不是访客本身的则去掉
                        _this.tableData[i].moment_comment.splice(k,1)
                        k--;
                      }
                    }
                  }
                }
              }
            }
            _this.next_page_url = res.data.data.moments.next_page_url
            if(!this.next_page_url || !res.data.data.moments.data.length){
              this.finished = true
            } 
          }
        })
      }else{
        this.loading = false;
        this.finished = true
      }
    },
    // 更换顶端背景图
    changeBg(){
      if(this.userBaseInfo.is_self == 1){
        document.getElementById('file0').click();
      }
    },
    // 背景图
    getimg(img){
      this.bg = img
    },
    // 评论样式
    change(e){
      this.sendActive=e.target.innerHTML
      if(!e.target.innerHTML){
        this.$store.state.comContent = e.target.innerHTML
      }
    },
    
    changeType(){
      this.display = false
      this.comShow = false
    },
    // afterRead(file) {
    //   // 此时可以自行将文件上传至服务器
    //   this.$router.push({
    //       path: '/editCircle',
    //       name:'editCircle',
    //       params:{
    //         file: file,
    //       }
    //   })
    // },
    // 图片的选择方式
    showPopup() {
      // this.friedShow = true
      this.$router.push({
        path: '/EditCircle',
        name:'EditCircle',query: {z_id:this.$route.query.z_id}
      })
    },
    toMessageList(){
      this.$router.push({
        path: '/MessageList',
        name:'MessageList',query: {z_id:this.$route.query.z_id,is_self:this.userBaseInfo.is_self} 
      })
    },
    changeFile(fileType){
       this.$router.push({
        path: '/EditCircle',
        name:'EditCircle',query: {z_id:this.$route.query.z_id,fileType:fileType}
      })
      // $("#tocircle").click()
      this.friedShow = false
    },
    // 展示图片查看预览
    showImg(i,index){
      // console.log(this.tableData[index],index,i)
      var showArryImg = []
      if(this.tableData[index].media_1){
        showArryImg.push(this.tableData[index].media_1)
      }
      if(this.tableData[index].media_2){
        showArryImg.push(this.tableData[index].media_2)
      }
      if(this.tableData[index].media_3){
        showArryImg.push(this.tableData[index].media_3)
      }
      if(this.tableData[index].media_4){
        showArryImg.push(this.tableData[index].media_4)
      }
      if(this.tableData[index].media_5){
        showArryImg.push(this.tableData[index].media_5)
      }
      if(this.tableData[index].media_6){
        showArryImg.push(this.tableData[index].media_6)
      }
      if(this.tableData[index].media_7){
        showArryImg.push(this.tableData[index].media_7)
      }
      if(this.tableData[index].media_8){
        showArryImg.push(this.tableData[index].media_8)
      }
      if(this.tableData[index].media_9){
        showArryImg.push(this.tableData[index].media_9)
      }
      

      ImagePreview({images:showArryImg,startPosition:i});
      // console.log(showArryImg)
      
    },
     // 删除朋友圈
    delCircle(index,id){
      this.$dialog.confirm({
        title: '提示',
        message: '确定删除本条朋友圈吗?',
      })
      .then(() => {
        this.$toast.loading({ 
          message: '删除中',
          forbidClick: true,
        });
        this.axios({
          method: 'DELETE',
          url: '/scrm/moment/'+id,
          data:{
            userId:this.baseInfo.userId,
            z_id:this.$route.query.z_id,
          }
        }).then((res) => {
          if(res.data.code == 0){
            this.tableData.splice(index, 1);
            this.$toast.clear();
            this.$toast.success('删除成功');
          }else{
            this.$toast(res.data.data);
          }
        })
      })
      .catch(() => {
        // on cancel
      });
      
    },
    // 删除评论
    delComment(){
      this.$toast.loading({ 
        message: '删除中',
        forbidClick: true,
      });
      this.axios({
        method: 'DELETE',
        url: '/scrm/moment/interaction/comment/'+this.commId,
      }).then((res) => {
        if(res.data.code == 0){
          this.tableData[this.e].moment_comment.splice(this.index,1)
          this.delectCom = false
          this.$toast.clear();
          this.$toast.success('删除成功');
        }
      })
    },
    // 发送评论
    send(){

      var data = {
        m_id:this.tableData[this.e].id,

        from:this.userBaseInfo.id,
        from_name:this.userBaseInfo.name,

        content:$(".pre").html(),
        tag:this.userBaseInfo.is_self,
        a_id:this.master.id,
        c_id:this.userBaseInfo.id,
        customer_remark:this.userBaseInfo.remark,
        avatar:this.userBaseInfo.avatar
      }
      // console.log(this.tableData,this.e)
      // this.tableData[this.e].moment_comment.push({
      //   tag:this.userBaseInfo.is_self,
      //   content:this.$store.state.comContent,
      //   from_name:this.userBaseInfo.name,
      // })
      // console.log(data)


      if(this.commType == 0){ //评论本条朋友圈
        data.to = ''
        data.to_name = ''
        data.to_tag = this.userBaseInfo.is_self
      }else{
        data.to = this.tableData[this.e].moment_comment[this.index].from
        data.to_name = this.tableData[this.e].moment_comment[this.index].from_name
        data.to_tag = this.tableData[this.e].moment_comment[this.index].tag
      }
      this.axios({
        method: 'POST',
        url: '/scrm/moment/interaction/comment',
        data:data
      }).then((res) => {
        if(res.data.code == 0){
          var showTtemCom = {
            tag:this.userBaseInfo.is_self,
            from:this.userBaseInfo.id,
            id:res.data.data.id,
            content:$(".pre").html(),
          }
          if(this.userBaseInfo.is_self == 1){ //朋友圈主人展示备注
            showTtemCom.from_name=this.userBaseInfo.remark
          }else{ //否则展示
            showTtemCom.from_name=this.userBaseInfo.name
          }
          if(this.commType != 0){ //评论本条朋友圈
            showTtemCom.to_name=data.to_name
          }
          this.tableData[this.e].moment_comment.push({...showTtemCom})
          // console.log(res,this.tableData[this.e].moment_comment)
          this.$forceUpdate()
          this.comShow = false
          this.$store.state.comContent = ''
          this.sendActive = ''
        }
      })
    },
    // 点击评论
    toComment(type,tag,e,id,index,commId){
      // console.log('??',commId)
      if(type == 0){ //评论本条朋友圈
        this.commType = 0
      }else{ //回复评论
        this.commType = 1
        this.e = e
        this.index = index
        this.commId = commId
        // 判断到底是回复还是删除
        if(this.userBaseInfo.is_self == tag){ //类型相同
          if(this.userBaseInfo.id == id){ //id相同
            // 删除 加复制 
            this.delectCom = true
            return
          }
        }
      }
      this.comShow = true
      this.display = false
      var _this = this
      setTimeout(function(){
        _this.$refs.pres.focus()
        $('#face').SinaEmotion($('#allEmotion'));
      },100)
    },
    // 聚焦去掉表情
    focus(){
      setTimeout(function(){
        $("#allEmotion #emotions").css('display','none')
      },100)
    },
    // 点赞 取消点赞
    toLike(){
      if(this.userBaseInfo.is_self == 1){
        return
      }
      this.axios({
        method: 'POST',
        url: '/scrm/moment/interaction/vote',
        data:{
          m_id:this.tableData[this.e].id,
          c_id:this.userBaseInfo.id,
          is_contact:this.userBaseInfo.is_self,
          v_name:this.userBaseInfo.name,
          v_remark:this.userBaseInfo.remark,
          avatar:this.userBaseInfo.avatar
        }
      }).then((res) => {
        // console.log(this,this.tableData)
        if(res.data.code == 0){
          this.tableData[this.e].vote_list.length = true
          if(this.tableData[this.e].voteCommon){
            if(this.userBaseInfo.is_self == 1){ //朋友圈主人展示备注
              this.tableData[this.e].voteCommon = this.userBaseInfo.v_remark+','+ this.tableData[this.e].voteCommon
            }else{ //否则不展示
              this.tableData[this.e].voteCommon = this.userBaseInfo.name+','+ this.tableData[this.e].voteCommon
            }
          }else{
            if(this.userBaseInfo.is_self == 1){ //朋友圈主人展示备注
              this.tableData[this.e].voteCommon = this.userBaseInfo.v_remark
            }else{ //否则不展示
              this.tableData[this.e].voteCommon = this.userBaseInfo.name
            }
          }
          this.$forceUpdate();
        }else if(res.data.code == 200){
          // 取消点赞
          if(this.userBaseInfo.is_self == 1){ //朋友圈主人展示备注
            var len = this.userBaseInfo.v_remark.length;
            var first = this.tableData[this.e].voteCommon.inedxOf(this.userBaseInfo.v_remark);
            if(this.tableData[this.e].voteCommon.length == len){ //只有一个就直接等于0
              this.tableData[this.e].voteCommon = 0;
              this.tableData[this.e].vote_list.length = 0
            }else if(first == this.tableData[this.e].vote_list.length - len){ //如果在最后
              this.tableData[this.e].voteCommon = this.tableData[this.e].voteCommon.substring(0,first) + this.tableData[this.e].voteCommon.substring(first-1+len)
            }else{ //多个就去掉自己
              this.tableData[this.e].voteCommon = this.tableData[this.e].voteCommon.substring(0,first) + this.tableData[this.e].voteCommon.substring(first+len+1)
            }
          }else{ //否则不展示
            var len1 = this.userBaseInfo.name.length;
            var first1 = this.tableData[this.e].voteCommon.indexOf(this.userBaseInfo.name);

            if(this.tableData[this.e].voteCommon.length == len1){
              this.tableData[this.e].voteCommon = 0;
              this.tableData[this.e].vote_list.length = 0
            }else if(first1 == this.tableData[this.e].voteCommon.length - len1){ //如果在最后 去掉前面的逗号
              this.tableData[this.e].voteCommon = this.tableData[this.e].voteCommon.substring(0,first1-1) + this.tableData[this.e].voteCommon.substring(first1+len1)
            }else{
              this.tableData[this.e].voteCommon = this.tableData[this.e].voteCommon.substring(0,first1) + this.tableData[this.e].voteCommon.substring(first1+len1+1)
            }
          }
          this.$forceUpdate();
        }
        this.display = false
      })
      
    },
    // 点击出现点赞和评论悬浮框
    showTwoClick(event,e){
      if(e == this.e){
        this.display = !this.display
      }else if(this.display){
        this.display = !this.display
        var _this = this;
        setTimeout(function(){
          _this.display = !_this.display
        },100)
      }else{
        this.display = !this.display
      }
      this.e = e
      this.$refs["hearcom"].style.top = event.currentTarget.offsetTop -10 + 'px'
    }
  },
  beforeDestroy(){
    var _this = this;
    document.removeEventListener('click',_this.changeType);
  },
}
</script>

<style>
.detailcomments img,.changehtml img{
  width: 0.16rem;
  height: 0.16rem;
  display: inline-block;
  padding: 0 0.05rem;
  vertical-align: middle;
  margin-top: -3px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.messageTitle{
  >div{
    padding: 0.05rem 0.2rem ;
    background: #616161;
    font-size: 0.16rem;
    color: white;
    display: flex; 
    align-items: center;
    border-radius: 0.05rem;
    >img{
      width: 0.3rem;
      border-radius: 0.05rem;
      margin-right: 0.15rem;
    }
  }
  display: flex;  
  justify-content: center;
  margin-bottom: 0.5rem;
}
.tocomment{
  position: fixed;
  bottom: 0;
  width: 100%;
  >div{
    display: flex;
    align-items: flex-end;
    padding: 0.08rem 0.1rem;
    background: #f7f7f7;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid #dedede;
    >.trytoimg{
      position: relative;
      overflow-x: hidden;
      min-height: 34px;
      overflow-y: auto;
      height: auto;
    }
    .active{
      background: #08c163;
      color: white;
      border: 1px solid #08c163;
    }
    >span{
      width: 0.56rem;
      height: 0.28rem;
      line-height: 0.3rem;
      text-align: center;
      border-radius: 5px;
      font-size: 0.14rem;
      border: 1px solid #cacaca;
      color: #c0c0c0;
      margin-bottom: 5px;
    }
    >img{
      width: 0.27rem;
      height: 0.27rem;
      margin: 0 0.08rem 7px;
      display: block;
    }
    >textarea{
      flex:1;
      padding: 8px 16px;
      box-sizing: border-box;
      border: none;
      outline: none;
      resize: none;
      font-size: 14px;
    }
    div{
      border: none;
      flex: 1;
    }
  }
}

.bottompopup{
  >div{
    height: 0.52rem;
    text-align: center;
    line-height: 0.58rem;
    font-size: 16px;
    color: #141414
  }
  >div:nth-child(1){
    height: 0.58rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: normal;
    span:nth-child(2){
      font-size: 0.12rem;
      color: #6a6a6a;
      margin-top: 0.06rem;
    }
  }
  >div:nth-child(2){
    border-top: 1px solid #f6f6f6;
    border-bottom: 1px solid #f6f6f6;
  }
  >div:nth-child(3){
    height: 0.1rem;
    background:#f9f9f9
  }
  >div:nth-child(4){
    border-top: 1px solid #f6f6f6;
  }
}

.hearcom{
  background:#4c4c4a;
  display: flex;
  height: 0.38rem;
  border-radius: 5px;
  position: absolute;
  right: 0.4rem;
  -webkit-animation-name: fadeIn; /*动画名称*/
  -webkit-animation-duration: 0.3s; /*动画持续时间*/
  -webkit-animation-iteration-count: 1; /*动画次数*/
  -webkit-animation-delay: 0s; /*延迟时间*/
  div{
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.14rem;
    margin: 0 20px;
    img{
      height: 0.18rem;
      margin-right: 7px;
    }
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; /*初始状态 透明度为0*/
    right: -1.8rem;
  }
  50% {
    opacity: 0.5; /*中间状态 透明度为0*/
  }
  100% {
    opacity: 1; /*结尾状态 透明度为1*/
    right: 0.4rem;
  }
}

.content{
  padding: 0.6rem 0 0;
  position: relative;
  overflow: hidden;
  .selfitem{
    padding:0.15rem 0.1rem 0.1rem;
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    >div{
      text-align: left;
      padding-left: 0.1rem;
      flex: 1;
      .comments{
        background: #f8f8f8;
        border-radius: 3px;
        padding: 0.04rem 0 0.08rem;
        margin-top: 0.1rem;
        font-size: 14px;
        >.clickheart{
          color: #576a94;
          font-weight: 600;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          line-height: 0.2rem;
          padding: 0 0.08rem;
          img{
            width: 13px;
            width: 14px;
            margin-right: 3px;
            margin-top: -3px;
            vertical-align: middle;
          }
        }
        .line{
          border-top: 1px solid #f1f1f1;
          margin: 0.02rem 0 0.06rem;
        }
        >.detailcomments{
          padding: 0 0.08rem;
          >div{
            font-size: 0.14rem;
            line-height: 0.16rem;
            margin-top: 0.03rem;
            color: #141414;
            white-space: pre-wrap;      
            white-space: -moz-pre-wrap; 
            white-space: -pre-wrap;     
            white-space: -o-pre-wrap;   
            word-wrap: break-word; 
            >span{
              color: #576a94;
              font-weight: 600;
            }
          }
          >div:nth-child(1){
            margin-top: 0;
          }
        }
      }
      .img1{
        margin-top: 0.1rem;
        img{
          height: 188px;
          max-width: 60%;
        }
      }
      .img3,.img6,.img7,.img9,.img8,.img5{
        margin-top: 0.1rem;
        justify-content: space-between;
        img{
          min-width: 100%;
          max-width: 200%;
          min-height: 100%;
        }
        div{
          width:  33%;
          margin-top: 3px;
          position: relative;
          padding-bottom: 33%;
          height: 0;
          overflow: hidden;
        }
      }
      .img4,.img2{
        margin-top: 0.1rem;
        width: 60%;
        justify-content: space-between;
        img{
          min-width: 100%;
          max-width: 200%;
          min-height: 100%;
          // clip: rect(0px,10px,10px,0px);
          position: absolute;
        }
        div{
          width: 49.3%;
          margin-top: 3px;
          position: relative;
          padding-bottom: 49.3%;
          height: 0;
          overflow: hidden;
        }
      }
      >div:nth-child(3){
        flex-wrap: wrap;
        display: flex;
        padding-right: 0.2rem;
      }
      p:nth-child(1){
        color: #576a94;
        font-size: 0.16rem;
        line-height: 0.26rem;
        font-weight: 600;
      }
      p:nth-child(2){
        color: #141414;
        font-size: 0.16rem;
        line-height: 0.2rem;
      }
      >div:nth-child(4){
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        height: 0.2rem;
        line-height: 0.2rem;
        font-size: 0.13rem;
        color: #6a6a6a;
        >span:nth-child(1){
          span{
            margin-left: 0.1rem;
            color: #576a94;;
            display: inline-block;
          }
        }
        >span:nth-child(2){
          background: #f8f8f8;
          border-radius: 3px;
          span{
            width: 0.3rem;
            display: inline-block;
            height: 0.2rem;
            color: #576a94;
            background: url(~@/assets/image/ellipsis.png) no-repeat;
            background-size: auto 0.04rem;
            background-position: center;
          }
        }
      }
    }
    >img{
      width: 0.45rem;
      display: inline-block;
      height: 0.45rem;
      border-radius: 5px;
    }
  }
}
.headerimg{
  position: relative;
  height: 3rem;
  >p{
    position: absolute;
    bottom: 0;
    right: 0.8rem;
    font-size: 0.16rem;
    color: white;
    text-shadow: 0.1em 0.1em 0.1em black;
  }
  >.backimg{
    height: 3rem;
    overflow: hidden;
    >div{
      height: 1rem;
      width: 100%;
      position: absolute;
      opacity: 0.5;
      background: linear-gradient(180deg, #0000004d, #ffffff00,#ffffff00,#ffffff00);
      top: 0;
    }
    img{
      width: 100%;
      margin-top: -0.35rem;
    }
  }
  .tocircle{
    width: 0.3rem;
    position: absolute;
    right: 0.08rem;
    top: 0.15rem;
    margin-top: 0;
  }
  .bottomright{
    width: 0.65rem;
    position: absolute;
    border-radius: 0.05rem;
    right: 0.08rem;
    bottom: -0.325rem;
  }
}
</style>

